// Cyborg 4.3.1
// Bootswatch

//
// Color system
//

$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ADAFAE !default;
$gray-500: #888 !default;
$gray-600: #555 !default;
$gray-700: #282828 !default;
$gray-800: #222 !default;
$gray-900: #212529 !default;
$black:    #000 !default;

$blue:    #2A9FD6 !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #CC0000 !default;
$orange:  #fd7e14 !default;
$yellow:  #FF8800 !default;
$green:   #77B300 !default;
$teal:    #20c997 !default;
$cyan:    #9933CC !default;

$primary:       $blue !default;
$secondary:     $gray-600 !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-800 !default;
$dark:          $gray-400 !default;

$yiq-contrasted-threshold:  175 !default;


// Body

$body-bg:       #060606 !default;
$body-color:    $gray-500 !default;

// Fonts

$font-family-sans-serif: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !default;

$font-size-base: .875rem !default;

$h1-font-size: 4rem !default;
$h2-font-size: 3rem !default;
$h3-font-size: 2.5rem !default;
$h4-font-size: 2rem !default;
$h5-font-size: 1.5rem !default;

$headings-color:         $white !default;

// Tables

$table-accent-bg:               rgba($white,.05) !default;
$table-hover-bg:                rgba($white,.075) !default;

$table-border-color:            $gray-700 !default;

$table-dark-bg:               $gray-500 !default;
$table-dark-border-color:     darken($gray-500, 7.5%) !default;
$table-dark-color:            $body-bg !default;

// Buttons

$input-btn-padding-x:       1rem !default;

// Forms

$input-disabled-bg:              $gray-400 !default;

$input-border-color:             transparent !default;

$input-group-addon-color:           $white !default;
$input-group-addon-bg:              $gray-700 !default;

$custom-file-color:                 $white !default;
$custom-file-border-color:          $gray-700 !default;

// Dropdowns

$dropdown-bg:                    $gray-700 !default;
$dropdown-divider-bg:            $gray-800 !default;

$dropdown-link-color:            $white !default;
$dropdown-link-hover-color:      $white !default;
$dropdown-link-hover-bg:         $primary !default;

// Navs

$nav-tabs-border-color:                       $table-border-color !default;
$nav-tabs-link-hover-border-color:            $nav-tabs-border-color !default;
$nav-tabs-link-active-color:                  $white !default;
$nav-tabs-link-active-bg:                     $nav-tabs-border-color !default;
$nav-tabs-link-active-border-color:           $nav-tabs-border-color !default;

// Navbar

$navbar-dark-hover-color:           $white !default;

// Pagination

$pagination-color:                     $white !default;
$pagination-bg:                        $gray-700 !default;
$pagination-border-color:              transparent !default;

$pagination-hover-color:               $white !default;
$pagination-hover-bg:                  $primary !default;
$pagination-hover-border-color:        $pagination-border-color !default;

$pagination-disabled-bg:               $pagination-bg !default;
$pagination-disabled-border-color:     $pagination-border-color !default;


// Jumbotron

$jumbotron-bg:                   $gray-700 !default;

// Cards

$card-bg:                  $gray-700 !default;

// Tooltips

$tooltip-bg:                  $gray-700 !default;
$tooltip-opacity:             1 !default;

// Popovers

$popover-bg:                          $gray-700 !default;

// Modals

$modal-content-bg:               $gray-800 !default;

$modal-header-border-color:   $gray-700 !default;

// Progress bars

$progress-bg:                   $gray-700 !default;

// List group

$list-group-bg:                  $gray-800 !default;
$list-group-border-color:        $gray-700 !default;

$list-group-hover-bg:                 $primary !default;

$list-group-disabled-bg:         $gray-700 !default;

$list-group-action-active-bg:         $primary !default;

// Breadcrumbs

$breadcrumb-bg:                 $gray-700 !default;

// Close

$close-color:                 $white !default;
$close-text-shadow:           none !default;

// Code

$pre-color:                         inherit !default;
